import { computed } from 'vue'
import { useRoute, useRouter } from 'vue-router/composables'
import { MenuItem } from '/~/composables/cms/use-cms'
import { useExtensions } from '/~/composables/extensions'
import { useUI } from '/~/composables/ui'

export function useHeaderTopMenu() {
  const { getManifestByName } = useExtensions()

  const isNotifications = computed(() =>
    Boolean(getManifestByName('notifications'))
  )

  function isDisabled(item: MenuItem) {
    return item.action?.value === 'show-notifications' && !isNotifications.value
  }

  function isToggleProfileItem(item: MenuItem) {
    return [
      'show-ewallet-menu',
      'show-optus-profile',
      'show-bill-payments-profile',
    ].includes(`${item.action?.value}`)
  }

  const { uiReady, topMenu } = useUI()
  const router = useRouter()
  const route = useRoute()

  const activeTopMenuItem = computed(() => {
    if (!uiReady.value || !topMenu.value) return

    const currentFullPath = route.fullPath

    for (const item of topMenu.value) {
      const isActionPage = item.action && item.action.type === 'page'

      if (isActionPage) {
        for (const routeMatch of route.matched) {
          if (routeMatch.name === item.action.value) return item
        }
      }

      if (checkChildren(item)) {
        return item
      } else if (isActionPage) {
        const { resolved } = router.resolve({ name: item.action.value })
        const resolvedFullPath = resolved.fullPath.slice(
          0,
          resolved.fullPath.length - 1
        )

        if (
          currentFullPath.indexOf(resolvedFullPath) === 0 &&
          resolvedFullPath.length > 0
        ) {
          return item
        }
      }
    }

    return null
  })

  function checkChildren(item: MenuItem): MenuItem | null {
    const { children = [] } = item

    for (const child of children) {
      const { action } = child
      const childItem = checkChildren(child)

      if (childItem) {
        return childItem
      } else if (action && action.type === 'page' && action.value) {
        for (const routeMatch of route.matched) {
          if (routeMatch.name && routeMatch.name.indexOf(action.value) >= 0) {
            return item
          }
        }
      }
    }

    return null
  }

  return { isDisabled, isToggleProfileItem, activeTopMenuItem }
}
